import React, { Component } from "react";
import play from "../../src/assets/img/play.svg";
import apple from "../../src/assets/img/apple.svg";
import splash1 from "../../src/assets/img/splash-21.png";
import splash2 from "../../src/assets/img/splash-1.png";
import "./Herosection.css";
import { Heading, Text } from "@chakra-ui/react";


const Herosection = () => {
  return (
    <section className="w-100 overflow-hidden hero-section text-center text-md-start">
      <div
        className="row w-100 justify-content-between py-5 row-alt mx-0 px-2 px-lg-5 px-xxl-5 px-xl-5 px-md-5 "
        style={{}}
      >
        <div className="col-lg-6 col-sm-12 col-md-12 p-lg-5 p-md-4">
          <div className="caption-ctn">
            {/* <Heading as='h1' size={{ base: 'xl', md: '3xl' }} mb={4}>
              FOR THE <span className="yellow-text mx-2">UNDYING <br /> LOVE</span>
              WE HAVE FOR <br /> OUR PETS
            </Heading> */}
            <Heading as='h1' size={{ base: 'xl', md: '3xl' }} mb={4}>
              PET <span className="yellow-text mx-2">MANAGEMENT <br /></span>
              MADE <span className="yellow-text mx-2">EASY </span>
            </Heading>
            <Text fontSize={{ base: 'xl', md: '2xl'  }}>
              Because we know you love your pets, My Pet & I is giving you the platform to take better care of them on the go.
              You can save your pets bio-data with us, find mates and track vaccination records..... 
            </Text>
            {/* <p className="hero-subtext mt-3 ">
              Because we know you love your pets, My Pet & I is giving you the platform to take better care of them on the go.
              You can save your pets bio-data with us, find mates and track vaccination records..... 
            </p> */}
            <div className="download-links row-links  w-100 row justify-content-center gy-3 mb-3 gx-2">
              <div className="col-auto ">
                <a
                  href="https://play.google.com/store/apps/details?id=com.mypetndi&hl=en&gl=US"
                  className="play-link btn d-flex   text-center align-items-center justify-content-center "
                  style={{ alignItems: "center" }}
                >
                  <img src={play} alt="play" className="play" />
                  <div className="div">
                    <p className="get-text">GET IT ON</p>
                    <p className="store-text">Google Play</p>
                  </div>
                </a>
              </div>
              <div className="col-auto">
                {" "}
                <a href="https://apps.apple.com/ng/app/my-pet-and-i/id6444916938" className="play-link btn d-flex  text-center align-items-center  ">
                  <img src={apple} alt="play" className="play" />
                  <div className="div ms-2">
                    <p className="get-text">Download on the</p>
                    <p className="store-text">App Store</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-sm-12 col-md-12 justify-content-end d-lg-flex d-md-flex p-md-5">
          <div className="splash-2">
            <img src={splash2} alt="" className="img-fluid mx-auto" />
          </div>
          <div className="splash-1">
            <img src={splash1} alt="" className="img-fluid mx-auto" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Herosection;
