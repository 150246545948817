import { Box, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import React from 'react'
import Footersection from '../components/Footersection';
import Nav from '../components/Nav';

export const Privacy = () => {
    return (
        <div>
            <Nav />
            <Box bg='#FDD500' w='100%' p={'4rem'} color='black' alignItems={"center"}>
                <Text fontSize={30} fontWeight='bold' textAlign={"center"} fontFamily={"NexaBold"}>
                    Privacy Policy
                </Text>
            </Box>
            <Box px={{base: '2rem', md: '4em'}} py={"3rem"}>

                <Text>
                    <Text fontSize={18} fontWeight='bold' fontFamily={"NexaBold"}>
                        INTRODUCTION
                    </Text>
                    Welcome to My Pets and I! We work hard to protect your personal information and keep your information private and secure. This Privacy Policy outlines the type of personal information that My Pet and I ("My Pet and I LTD ") collects when operating the My Pets and I website and mobile application (referred to as "the Platform"). Your privacy is extremely important to us. If you have any questions, please send us an email at info@mypetndi.com.
                    This Privacy Policy governs how My Pet and I collect, use, maintain and disclose information collected from users (each, a "User") of the Platform. This Privacy Policy applies to all products and services offered on the Platform.
                    <br /><br />
                    <Text fontSize={18} fontWeight='bold' fontFamily={"NexaBold"}>
                        SUMMARY
                    </Text>

                    Information that we collect from you
                    <UnorderedList>
                        <ListItem>We collect device-related information, server log information, and other types of anonymous information when you visit the Platform.</ListItem>
                        <ListItem>Additionally, we collect personal information when you signup and use your account on the Platform.</ListItem>
                    </UnorderedList>

                    Why do we collect your personal information?
                    <UnorderedList>
                        <ListItem>We use your personal information to maintain your account, provide customer service, and enhance your customer experience.</ListItem>
                        <ListItem>We use aggregate personal information and non-personal information to improve the Platform and monitor and protect the security of our service.</ListItem>
                    </UnorderedList>

                    Whom we share your personal information with
                    <UnorderedList>
                        <ListItem>We do not sell, rent, or trade your personal information with any third parties except as provided in this Privacy Policy, with your consent, or as required by law.</ListItem>
                    </UnorderedList>
                    <br />
                    <Text fontSize={18} fontWeight='bold' fontFamily={"NexaBold"}>
                        HOW DO YOU ACCEPT THIS POLICY?
                    </Text>
                    By choosing to use the Platform, or otherwise providing us with Registration Information, you hereby consent to the processing of your Registration Information, this Privacy Policy and the Terms and Condition for the use of the Platform.
                    <br /><br />
                    <Text fontSize={18} fontWeight='bold' fontFamily={"NexaBold"}>
                        INFORMATION WE COLLECT AND WHY
                    </Text>

                    To provide a high-quality service, My Pet and I LTD collects various types of information when you visit the Platform or sign-up as a User.
                    <br />
                    <b>As a Visitor</b><br />
                    We collect various types of anonymous information about visitors to the Platform, such as device-related information (browser type, IP address) and server log information (date and time of day of your visit). We also collect information that you input into the Platform. We use this information to better understand our visitors and our business and to enhance the Platform.
                    <br />
                    <b>As a User</b><br />
                    When you choose to create an account on the Platform, we will also collect certain personal information such as:
                    <UnorderedList>
                        <ListItem>First Name;</ListItem>
                        <ListItem>Last Name;</ListItem>
                        <ListItem>Phone Number;</ListItem>
                        <ListItem>Email;</ListItem>
                        <ListItem>Password;</ListItem>
                    </UnorderedList>

                    We may also request documents such as:
                    <br />
                    <UnorderedList>
                        <ListItem>Pedigree Certificate for Pet;</ListItem>
                        <ListItem>Proof of Identification</ListItem>
                        <ListItem>Proof of Professional Certification</ListItem>
                    </UnorderedList>
                    <br />
                    Collectively known as "Registration Information".
                    <br />
                    <b>Non-Registration Information</b>
                    <br />
                    We may collect non-registration information about Users whenever they interact with the Platform. Non-registration information may include the browser name, the type of computer and technical information about the User’s means of connection to the Platform, such as the operating system and the Internet service providers utilized and other similar information.
                    <br /><br />
                    <Text fontSize={18} fontWeight='bold' fontFamily={"NexaBold"}>
                        COOKIES & PIXELS
                    </Text>
                    Cookies are small pieces of data that are stored on your computer, mobile phone or another device. Pixels are small code blocks on a website that allow another server to measure viewing of a webpage and are often used in connection with cookies.
                    <br />
                    We use these technologies to customize the Platform and improve your experience. Cookies store anonymous information, such as whether you viewed the Platform from your mobile device or computer or tablet. We may store some personal information in a cookie and/or pixel, such as the website that you visited immediately before visiting the Platform and relevant information that allows us to enhance your user experience and for marketing purposes. Additionally, we may use a cookie and/or that only stores anonymous information to track visitors across the Platform to better customize our marketing and advertising campaigns.
                    <br />
                    Cookies, pixels, and similar technologies are not required for the Platform functionality. You are not required to accept any cookies or pixels to use the Platform. However, refusing to accept cookies or pixels will make the use of the Platform more cumbersome and less accessible.
                    <br />
                    You may choose to set your web browser to refuse cookies or to alert you when cookies are being sent. If they do so, note that some parts of the Platform may not function properly.
                    <br /><br />
                    <Text fontSize={18} fontWeight='bold' fontFamily={"NexaBold"}>
                        HOW WE USE YOUR REGISTRATION INFORMATION
                    </Text>
                    We use your registration information to enhance your customer experience, operate and maintain your account per Nigerian law, investigate and understand how the Platform is used, monitor and protect the security and integrity of the Platform, and better market and advertise our services.
                    <br />
                    As part of this process, we may from time-to-time aggregate some of your personal information in certain data analyses, reports, or other interpretations of pet-related trends for both internal and external purposes. When aggregating personal information, we make sure that the information is not identifiable to any particular customer.
                    <br /><br />
                    <Text fontSize={18} fontWeight='bold' fontFamily={"NexaBold"}>
                        WHEN WE SHARE YOUR INFORMATION
                    </Text>
                    We do not sell, rent, or trade your registration information with any third parties without your consent or as required by law.
                    <br />
                    To provide our services, we may share your registration information with third parties, such as consumer identification verification services, to serve you as a User. We have entered into agreements that require that these third parties keep your information confidential.
                    <br />
                    We also may disclose some of the registration information we collect to service providers and companies that perform marketing and analytics services on our behalf.
                    <br />
                    We may disclose registration information in response to services of legal process, such as a court order, summons, subpoenas, or as permitted or required by law when we reasonably believe it is necessary or appropriate to investigate, prevent, or act regarding illegal activities, suspected fraud, frontrunning or scalping, situations involving potential threats to the physical safety of any person, or violations of our Terms of Use or customer agreements.
                    <br />
                    Information about our users, including personal information, may be disclosed or transferred to entities now or in the future affiliated with My Pet and I LTD or as part of any merger, acquisition, change of control, debt financing, insolvency, bankruptcy or sale of our assets. Such information may be used in the businesses of any entity so receiving it.
                    <br /><br />
                    <Text fontSize={18} fontWeight='bold' fontFamily={"NexaBold"}>
                        CHILDREN
                    </Text>
                    Our service is not directed towards anyone under the age of 18. If a parent or guardian becomes aware that his or her child under the age of 18 has created an account with us and/or provided us with personally identifiable information, please contact us at info@mypetndi.com.
                    <br />
                    Additionally, if we become aware at any point that a child under the age of 18 is using our service, we will terminate his or her account.
                    <br /><br />
                    <Text fontSize={18} fontWeight='bold' fontFamily={"NexaBold"}>
                        HOW YOU CAN ACCESS OR CHANGE THE REGISTRATION INFORMATION THAT WE HAVE COLLECTED
                    </Text>
                    Once you have registered on the Platform, you can access your profile, review the information that is stored, and revise that information. If you have any problems, you may also contact us at info@mypetndi.com.
                    <br /><br />
                    <Text fontSize={18} fontWeight='bold' fontFamily={"NexaBold"}>
                        PROTECTION OF REGISTRATION INFORMATION
                    </Text>
                    My Pet and I LTD works diligently to protect your registration information provided. We employ several physical and electronic safeguards to keep your information safe. We use the strongest available browser encryption, store all of our data on servers in a secure facility and implement systematic processes and procedures for securing and storing data. We limit access to your personal information to only those employees with authorized access, and we require third parties who perform services for us to agree to keep your information confidential. Furthermore, if you choose to close your account or your account is terminated with us, we will continue to adhere to the privacy policies and practices outlined here.
                    In addition, further to the Data Protection Regulation 2019, your Registration Information shall be:
                    <UnorderedList>
                        <ListItem>collected and processed under specific, legitimate and lawful purpose as consented to by you;</ListItem>
                        <ListItem>adequate, accurate and without prejudice to the dignity of the human person;</ListItem>
                        <ListItem>stored only for the period within which it is reasonably needed and</ListItem>
                        <ListItem>secured against all foreseeable hazards and breaches such as theft, cyberattack, viral attack, dissemination, manipulations of any kind, damage by rain, fire or exposure to other natural elements.</ListItem>
                    </UnorderedList>
                    <br />
                    <Text fontSize={18} fontWeight='bold' fontFamily={"NexaBold"}>
                        YOUR RIGHTS AS A USER
                    </Text>
                    Users of the Platform possess the following rights:
                    <UnorderedList>
                        <ListItem>Right to request from the My Pet and I LTD, access to and rectification or erasure of your Registration Information;</ListItem>
                        <ListItem>Right to withdraw consent at any time which shall not affect the processing of your Registration Information provided with consent before its withdrawal;</ListItem>
                        <ListItem>Right to complain to the National Information Technology Development Agency (NITDA);</ListItem>
                        <ListItem>Right to object to the processing of your Registration Information for marketing;</ListItem>
                        <ListItem>Right to request the deletion of your Registration Information;</ListItem>
                        <ListItem>Right to request your Registration Information which will be provided in a structured, commonly used and machine-readable format.</ListItem>
                    </UnorderedList>
                    <br />
                    <Text fontSize={18} fontWeight='bold' fontFamily={"NexaBold"}>
                        THIRD-PARTY WEBSITES
                    </Text>
                    Users may find other content on our Platform that links to our partners' sites and services or other third parties. We do not control the content or links that appear on these sites and are not responsible for the practices employed by websites linked to or from the Platform. In addition, these sites or services, including their content and links, may be constantly changing. These sites and services may have their privacy policies and customer service policies. Browsing and interaction on any other website, including websites which have a link to the Platform, is subject to that website's terms and policies.
                    <br /><br />
                    <Text fontSize={18} fontWeight='bold' fontFamily={"NexaBold"}>
                        ADVERTISING
                    </Text>

                    Ads appearing on the Platform may be delivered to Users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile non-registration information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This Privacy Policy does not cover the use of cookies by any advertisers.
                    <br /><br />
                    <Text fontSize={18} fontWeight='bold' fontFamily={"NexaBold"}>
                        CHANGES TO THE POLICY
                    </Text>
                    We may modify this Privacy Policy over time. Any changes to the Privacy Policy will be reflected on this page and will become effective immediately upon posting. If the changes are significant, we will do our best to notify you via email or through a notification on the Platform or via email. Please check the effective date below to determine if there have been any changes since you last reviewed our Privacy Policy. You acknowledge and agree that you must review this Privacy Policy periodically and become aware of modifications.
                    <br /><br />
                    <Text fontSize={18} fontWeight='bold' fontFamily={"NexaBold"}>
                        INTERNATIONAL VISITORS
                    </Text>
                    This Service is hosted in Nigeria. If you are an international visitor, you should note that by providing your personal information, you are: <br/>
                    (i) permitting the transfer of your personal information to Nigeria which may not have the same degree of data protection laws as the country in which you reside; <br/> 
                    (ii) permitting the use of your personal information by this Privacy Policy.
                    <br /><br />
                    <Text fontSize={18} fontWeight='bold' fontFamily={"NexaBold"}>
                        YOUR ACCEPTANCE OF THESE TERMS
                    </Text>
                    By using the Platform, you signify your acceptance of this Privacy Policy. If you do not agree to this Privacy Policy, please do not sign up as a User on the Platform. Your continued use of the Platform following the posting of changes to this Privacy Policy will be deemed your acceptance of those changes.
                    <br /><br />
                    <Text fontSize={18} fontWeight='bold' fontFamily={"NexaBold"}>
                        CONTACTING US
                    </Text>
                    If you have any questions about this Privacy Policy, the practices of the Platform, or your dealings with the Platform, please contact us at <a href="emailto:info@mypetndi.com">info@mypetndi.com</a>.
                </Text>
            </Box>
            <Footersection />
        </div>
    )
}