import React, { Component } from 'react';
import Featuresection from '../components/Featuresection';
import Footersection from '../components/Footersection';
import Herosection from '../components/Herosection';
import HowitWorks from '../components/HowitWorks';
import Nav from '../components/Nav';
import Subscribesection from '../components/Subscribesection';

export const Homepage = () => {
    return (
        <>
            <Nav />
            <Herosection />
            <Featuresection />
            <HowitWorks />
            <Subscribesection />
            <Footersection />
        </>
    );
}

