import React, { Component } from "react";
import "./Howitworks.css";
import cloud from "../../src/assets/img/cloud.svg";
import arrow from "../../src/assets/img/arrow.svg";
import app from "../../src/assets/img/app.svg";
import person from "../../src/assets/img/person.svg";
import playalt from "../../src/assets/img/play-alt.svg";
import applealt from "../../src/assets/img/apple-alt.svg";
import { Heading, Text } from "@chakra-ui/react";



const HowitWorks = () => {
  return (
    <section
      className="w-100 overflow-hidden px-3 px-lg-5 px-xxl-5 px-xl-5 px-md-5 hiw-section"
      id="hiwsection"
    >
      <div
        className="row w-100 justify-content-between py-5 row-alt m-0"
        style={{}}
      >
        <h3 className="feature-header text-center mb-5">HOW IT WORKS</h3>
        <div className="row w-100 justify-content-evenly mb-5">
          <div className="col-md-3 cloud-ctn ">
            <div className="top-ctn">
              <div className="cloud-obj">
                <img src={cloud} />
              </div>
            </div>
            <div className="p-3 p-md-4">
              <Heading as='h3' size={{ base: 'xl', md: '1xl' }} className="hiw-caption">Free Download</Heading>
              <Text fontSize={{ base: 'lg', md: 'xl' }} mb={4} className="text-center">
                App download is completely free. You can simply go to the your
                google play stor for android users or IOS store for apple users.
              </Text>
            </div>
          </div>
          <div className="col arrow-ctn">
            <img src={arrow} alt="" className="arrow" />
          </div>
          <div className="col-md-3 person-ctn">
            <div className="top-ctn">
              <div className="person-obj">
                <img src={person} />
              </div>
            </div>
            <div className="p-3 p-md-4">
              <Heading as='h3' size={{ base: 'xl', md: '1xl' }} className="hiw-caption">Create Profile</Heading>
              <Text fontSize={{ base: 'lg', md: 'xl' }} mb={4} className="text-center">
                The app is easy to navigate. Sign up and create a new profile in
                the easy steps. You’ll be able to create pet cards for each of
                your pets.
              </Text>
            </div>
          </div>
          <div className="col arrow-ctn">
            <img src={arrow} alt="" className="arrow" />
          </div>
          <div className="col-md-3 app-ctn  ">
            <div className="top-ctn">
              {" "}
              <div className="app-obj">
                <img src={app} />
              </div>
            </div>
            <div className="p-3 p-md-4">
              <Heading as='h3' size={{ base: 'xl', md: '1xl' }} className="hiw-caption">enjoy the app</Heading>
              <Text fontSize={{ base: 'lg', md: 'xl' }} mb={4} className="text-center">
                Meet with various pet owners, choose from various vets in your
                vicinity, track you pets heat and keep track of your pet’s
                vaccination all in one app.
              </Text>
            </div>
          </div>
        </div>


        <div className="download-links row gx-md-5 gy-4 w-100 m-0    justify-content-center">
          <div className="col-auto link-ctn">
            {" "}
            <a href="#" className="play-link-alt">
              <button className="btn play-btn">
                <img src={playalt} alt="" className="play-alt" />
                <div className="inside-btn ">
                  <p className="top-text">GET IT ON</p>
                  <p className="down-text">Google Play</p>
                </div>
              </button>
            </a>
          </div>
          <div className="col-auto link-ctn">
            <a href="#">
              <button className="btn apple-btn">
                <img src={applealt} alt="" className="apple-alt" />{" "}
                <div className="inside-btn">
                  <p className="top-text-alt">Download on the</p>
                  <p className="down-text-alt">App Store</p>
                </div>
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowitWorks;
