import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
    fonts: {
        heading: "Gilroy-Bold",
        body: "Gilroy-Regular",
        // heading: "Poppins",
        // body: "Poppins",
        // heading: `'NexaBold', sans-serif`,
        // body: `'NexaRegular', sans-serif`,
    },
})

export default theme;