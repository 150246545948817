import React, { Component, useEffect } from "react";
import vet from "../../src/assets/img/find-vet.png";
import vaccine from "../../src/assets/img/vaccination-calendar.png";
import stud from "../../src/assets/img/find-stud.png";
import heat from "../../src/assets/img/heat-tracker.png";
import wallet from "../../src/assets/img/wallet.png";
import halfscreen from "../../src/assets/img/half-mockup.png";
import walletarrow from "../../src/assets/img/wallet-arrow.svg";
import walletarrowjr from "../../src/assets/img/wallet-arrow-jnr.svg";
import withdrawarrow from "../../src/assets/img/withdraw-arrow.svg";
import withdrawarrowjr from "../../src/assets/img/withdraw-arrow-jnr.svg";
import "aos/dist/aos.css";
import Aos from "aos";

import "./Featuresection.css";
import { Heading, Text } from "@chakra-ui/react";


const Featuresection = () => {
  useEffect(() => {
    Aos.init({ duration: 2000, once: true, mirror: false, disable: "mobile" });
  }, []);
  return (
    <section
      className="w-100 px-3 px-lg-5 px-xxl-5 px-xl-5 px-md-5 feature-section overflow-hidden"
      id="featuresection"
    >
      <div className="row w-100  py-5 gx-5 justify-content-evenly gy-5 row-fix row-alt mx-0">
        <h3 className="feature-header text-center mb-lg-5">OUR FEATURES</h3>

        <div className="col-sm-12">
          <div className="row justify-content-around px-md-3">
            <div className="col-md-6 d-flex align-items-center order-last order-md-first order-lg-first order-xl-first order-xxl-first">
              <div className="p-4 p-md-5 text-center text-md-start">
                <Heading as='h1' size={{ base: 'xl', md: '3xl' }} mb={4} color="vet-header blue">Find My Vet</Heading>
                <Text fontSize={{ base: 'lg', md: '2xl' }}>
                  The find my vet feature potentially solves a prominent issue
                  of locating not just any vet, but a good one close to you.
                  This app with the click of a button will display the names of
                  certified vets and veterinary hospitals closest you with
                  visible ratings based on customer feedback.
                </Text>
              </div>
            </div>
            <div className="col-md-6 order-first  order-lg-last order-xl-last order-xxl-last img-div-ctn p-4 p-lg-5">
              <img src={vet} className="img-fluid" />
            </div>
          </div>
          <div className="row justify-content-around pt-5 px-md-3">
            {" "}
            <div className="col-md-6 img-div-ctn">
              <img src={stud} className="img-fluid" />
            </div>
            <div className="col-md-6 d-flex align-items-center text-center text-md-end">
              <div className="p-4 p-md-5">
                <Heading as='h1' size={{ base: 'xl', md: '3xl' }} mb={4} className="vet-header green">
                  Find My Stud
                </Heading>
                <Text fontSize={{ base: 'lg', md: '2xl' }}>
                  People looking for potential mates for their dogs will use this feature on the app, specifying
                  breed of choice while the app pulls up stud data and location from its database of stud dogs registered.
                </Text>
              </div>
            </div>
          </div>
          <div className="row justify-content-around pt-5 px-md-3">
            <div className="col-md-6 d-flex align-items-center order-last order-md-first order-lg-first order-xl-first order-xxl-first">
              <div className="p-4 p-md-5 text-center text-md-start">
                <Heading as='h1' size={{ base: 'xl', md: '3xl' }} mb={4} className="vet-header purple">
                  Medical History
                </Heading>
                <h3 className="vet-header purple"></h3>
                <Text fontSize={{ base: 'lg', md: '2xl' }}>
                  Most owners do not remember to
                  vaccinate their dogs or even follow up vaccinations; not because they don’t care, but rather because they get preoccupied and do not have
                  someone to remind them.

                  We added this simple feature just to remind you to vaccinate your pets and follow up on expired medications.
                </Text>
              </div>
            </div>
            <div className="col-md-6 img-div-ctn order-first  order-lg-last order-xl-last order-xxl-last p-4 p-md-5">
              <img src={vaccine} className="img-fluid" />
            </div>
          </div>
          <div className="row justify-content-around pt-5 px-md-3">
            {" "}
            <div className="col-md-6 img-div-ctn p-4 p-md-5">
              <img src={heat} className="img-fluid" />
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <div className="p-4 p-md-5 text-center text-md-end">
                <Heading as='h1' size={{ base: 'xl', md: '3xl' }} mb={4} className="light-blue">
                  Gestation Tracker
                </Heading>
                <Text fontSize={{ base: 'lg', md: '2xl' }}>
                  The gestation tracker is a feature added to assist and prepare female dog owners with the potential whelping dates for their dogs.
                </Text>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12">
          <div className="row justify-content-center pt-5">
            <div className="col-md-6">
              <h3 className="find-wallet-header text-center">
                Find My Pet Wallet
              </h3>
              <p className="find-wallet-sub-text text-center">
                My Pet &#38; I supports funding, receiving <br /> and sending
                money in app seamlessly.
              </p>
            </div>
          </div>
          <div className="row pt-5 justify-content-center">
            <div className="col-auto wallet-text-ctn order-last order-md-first order-lg-first order-xl-first order-xxl-first">
              <div className="info-ctn">
                <h3 className="find-wallet-header ">Deposit</h3>
                <p className="find-wallet-sub-text text-center left">
                  Deposit money to <br />
                  make in-app <br />
                  transaction
                  <br /> seamlessly..
                </p>
              </div>
              <div className="arrow-ctn-left">
                {" "}
                <img src={walletarrow} className="arrow-left" />
              </div>
            </div>
            <div className="col-md-4 text-center  order-lg-last order-xl-last order-xxl-last">
              <div className="arrow-ctn-top-left">
                <img src={walletarrowjr} className="arrow-top-left" />
              </div>
              <img src={wallet} className="img-fluid mt-0 wallet-pic" />
              <div className="arrow-ctn-top-left">
                <img src={withdrawarrowjr} className="arrow-top-right" />
              </div>
            </div>
            <div className="col-md-auto col-sm-12 withdraw-text-ctn order-last">
              <div className="arrow-ctn-right">
                <img src={withdrawarrow} className="arrow-right" />
              </div>

              <div className="info-ctn btm-info-ctn">
                <h3 className="find-wallet-header text-end">Withdraw</h3>
                <p className="find-wallet-sub-text text-center right">
                  Withdraw money
                  <br /> made from mating <br />
                  your stud with
                  <br /> another dog.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 p-0">
          <div className="row w-100 m-0 gy-5 py-3 py-lg-5 py-md-3 py-xxl-5 py-xl-5 justify-content-center">
            <div className="col-auto px-0">
              <div className="phone-ctn overflow-hidden text-center d-flex justify-content-center">
                <div
                  className="phone-1"
                  data-aos="fade-up"
                  data-aos-delay="300"
                  data-aos-duration="1100"
                  data-aos-once="false"
                  data-aos-mirror="false"
                >
                  <img src={halfscreen} className="phone-img" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 col-md-12 ms-lg-5 d-flex align-items-center">
              <div className="parallax-caption-ctn p-3 p-md-4">
                <h3 className="find-wallet-header text-center">
                  Adoption Home
                </h3>
                <Text size={{ base: 'lg', md: 'xl' }} mb={4} className="find-wallet-sub-text text-center mb-4">
                  Looking for pets to adopt?!
                  This makes it easier for pet lovers to find and home new pets from the comfort of their homes.
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Featuresection;
