import React, { Component, useState } from "react";
import logo from "../../src/assets/img/logo.svg"; import logomini from "../../src/assets/img/logo-mini.svg";

import toggle from "../../src/assets/img/menu btn.svg";
import "./Nav.css";



const Nav = () => {
  const [logoChange, setLogoChange] = useState(false)
  const logoChanger = () => {
    if (window.innerWidth <= 500) {
      setLogoChange(true);
    } else {
      setLogoChange(false);
    }
  }
  window.addEventListener("load", logoChanger)
  window.addEventListener("resize", logoChanger);
  window.addEventListener("focus", logoChanger);


  return (
    <nav
      className="navbar navbar-expand-lg px-3 px-lg-5 px-xxl-5 px-xl-5 px-md-5 py-3 w-100"
      style={{ background: "#fff" }}
    >
      <div className="container-fluid p-0">
        <a className="navbar-brand" href="#herosection">
          <img src={logoChange ? logomini : logo} className="img-fluid logo-icon" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <img src={toggle} />
        </button>
        <div
          className="collapse navbar-collapse"
          id="navbarNavDropdown"
        >
          <ul className="navbar-nav ">
            <li className="nav-item">
              <a className="nav-link active" href="/#herosection">
                HOME
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/#featuresection">
                FEATURES
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/#hiwsection">
                HOW IT WORKS
              </a>
            </li>
            <li className="nav-item " style={{ marginRight: "0px" }}>
              <button className="btn btn-purple text-center">DOWNLOAD</button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
