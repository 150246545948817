import React, { Component } from "react";
import "./App.css";
import { Homepage } from "./pages/home"
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import { Privacy } from "./pages/privacy";
import { ErrorPage } from "./pages/error";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./utility/theme";

// whatsapp number  +234 906 061 5026

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
