import banner from "../assets/img/banner.png";
import "./Subscribesection.css";
import insta from "../../src/assets/img/insta.svg";
import twitter from "../../src/assets/img/twitter.svg";
import whatsapp from "../../src/assets/img/whatsapp.svg";
import { Text } from '@chakra-ui/react';


const Subscribesection = () => {
  return (
    <section className="w-100 overflow-hidden px-3 px-lg-5 px-xxl-5 px-xl-5 px-md-5 subscribe-section">
      <div
        className="row w-100 justify-content-center  py-3 py-lg-5 py-md-3 py-xxl-5 py-xl-5 row-alt m-0"
        style={{}}
      >
        <div className="col-lg-6 col-md-12 col-sm-12">
          <img src={banner} alt="" className="subscribe-banner img-fluid" />
        </div>
        <div className="col-md-12 col-sm-12 col-lg-6 col-xxl-6 col-xl-6 text-center">
          <h3 className="subscribe-caption mb-3">
            Subscribe To Our Newsletter
          </h3>
          <Text fontSize={{ base: 'lg', md: '2xl' }} className="subscribe-subtext">
            Join our mailing list to receive the latest news and updates from
            our team
          </Text>
          <p >
           
          </p>
          <form className="subscribe-form mx-auto">
            <input type="email" className="form-control subscribe-input" />
            <button className="subscribe-btn btn" type="submit">
              Subscribe
            </button>
          </form>
        </div>
      </div>
      <div className="row w-100 overflow-hidden gx-5 justify-content-center mb-5 mx-0 gy-3 gy-md-0 gy-lg-0 gy-xxl-0 gy-xl-0">
        <div className="col-auto">
          <a href="https://wa.me/message/F7TTJYEZXGKKK1" className="bottom-link facebook-link">
            <div className="inside-link ">
              <img src={whatsapp} className="twitter-img d-block " />
              <p className="facebook d-block">Whatsapp</p>
            </div>
          </a>
        </div>
        {/* <div className="col-auto">
          <a href="#" className="bottom-link facebook-link">
            <div className="inside-link ">
              <img src={facebook} className="facebook-img d-block " />
              <p className="facebook d-block">Facebook</p>
            </div>
          </a>
        </div> */}
        <div className="col-auto  pt-md-1 pt-xl-1 pt-xxl-1 pt-lg-1">
          <a href="https://twitter.com/mypetandi_?s=11" className="bottom-link">
            <div className="inside-link ">
              <img src={twitter} className="twitter-img d-block" />
              <p className="twitter d-block">Twitter</p>
            </div>
          </a>
        </div>
        <div className="col-auto pt-md-1 pt-xl-1 pt-xxl-1 pt-lg-1">
          <a href="https://instagram.com/mypetandi.ig?utm_medium=copy_link" className="bottom-link">
            <div className="inside-link ">
              <img src={insta} className="insta-img" />
              <p className="insta">Instagram</p>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Subscribesection;
